/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require('./bootstrap');

require('datatables.net-bs4');
require('datatables.net-buttons-bs4');

import '@mdi/font/css/materialdesignicons.css';

window.Vue = require('vue');

import Vuetify from 'vuetify';
Vue.use(Vuetify);



/**
 * The following block of code may be used to automatically register your
 * Vue components. It will recursively scan this directory for the Vue
 * components and automatically register them with their "basename".
 *
 * Eg. ./components/ExampleComponent.vue -> <example-component></example-component>
 */

// const files = require.context('./', true, /\.vue$/i)
// files.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], files(key).default))

Vue.component('ladies-entry-golf-club', require('./components/Ladies/GolfClub.vue').default);
Vue.component('ladies-entry-player', require('./components/Ladies/Player.vue').default);
Vue.component('ladies-entry-form', require('./components/Ladies/Form.vue').default);
Vue.component('ladies-results-form', require('./components/Ladies/FormResults.vue').default);

Vue.component('admin-ladies-results-form', require('./components/Admin/Ladies/Results.vue').default);
Vue.component('admin-mens-results-form', require('./components/Admin/Mens/Results.vue').default);


/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

const app = new Vue({
    el: '#app',
    vuetify: new Vuetify(),
});

window.ladiesEntryPaid = function(id) {
	

	var type = $('#entry' + id).text().substring(5);
	//alert(type);
	let data = {
		id: id,
		type: type
	}

	var r = confirm("Please confirm 'Mark " + type + "' on id: " + id);
	if (r == true) {
		axios.post('/admin/ladies/entry/' + id, data)
		.then ((response) => {
			//console.log(response.data);
			if (type == 'Unpaid') {
				$('#entry' + id).text("Mark Paid");
				$('#row' + id + ' td:nth-child(4)').text('PENDING');
			} else {
				$('#entry' + id).text("Mark Unpaid");
				$('#row' + id + ' td:nth-child(4)').text('COMPLETED');
			}
			
		})
		.catch((error) => {
			console.log(error);
			alert('There was an error: ' + error.message);
		});
	}
}
