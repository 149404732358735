<template>
	<v-form ref="mensResults" @submit.prevent="mensResults" method="POST" lazy-validation v-model="valid">
		<v-card>
			<v-toolbar dark>
				<v-toolbar-title>Men's</v-toolbar-title>
			</v-toolbar>
			<v-container fluid>

				<v-row align="center">
					<v-file-input label="A Side PDF" outlined dense v-model="mensASide" name="mensASide"></v-file-input>
					<v-subheader>{{mensasideprop}}</v-subheader>
				</v-row>

				<v-row align="center">
					<v-file-input label="Consolation PDF" outlined dense v-model="mensConsolation" name="mensConsolation"></v-file-input>
					<v-subheader>{{mensconsolationprop}}</v-subheader>
				</v-row>
				
			</v-container>
		</v-card>

		<v-card>
			<v-toolbar dark>

				<v-toolbar-title>Senior's</v-toolbar-title>

			</v-toolbar>
			<v-container fluid>

				<v-row align="center">
					<v-file-input label="A Side PDF" outlined dense v-model="seniorsASide" name="seniorsASide"></v-file-input>
					<v-subheader>{{seniorsasideprop}}</v-subheader>
				</v-row>
				<v-row align="center">
					<v-file-input label="Consolation PDF" outlined dense v-model="seniorsConsolation" name="seniorsConsolation"></v-file-input>
					<v-subheader>{{seniorsconsolationprop}}</v-subheader>
				</v-row>
				
			</v-container>
		</v-card>





		<v-divider inset></v-divider>

		<v-divider inset></v-divider>
		<v-btn type="submit" ref="submit" rounded block color="primary" dark>Save</v-btn>
		<div class="form">
			<div class="form">
				<div class="g-recaptcha" data-sitekey="6LdEgEAUAAAAAHeMNcW_rv4iR9IP1j7vL7gMZxH3"></div>
			</div>
		</div>
	</v-form>
</template>
<script>
    export default {
        data: function() {
            return {
            	valid: true,
            	mensASide: null,
            	mensConsolation: null,
            	seniorsASide: null,
            	seniorsConsolation: null,
            }
        },
        props: ['mensasideprop', 'mensconsolationprop', 'seniorsasideprop', 'seniorsconsolationprop'],
        methods: {
    	mensResults: function() {
				//if ((this.$refs.p1.validate()) && (this.$refs.p2.validate()) && (this.$refs.clubs.validate())) {
		        if (this.$refs.mensResults.validate()) {
		        	let data = new FormData();

		        	if (this.mensASide != null) {
		        		data.append("mensASide", this.mensASide, this.mensASide.name);
		        	}
		        	
		        	if (this.mensConsolation != null) {
		        		data.append("mensConsolation", this.mensConsolation, this.mensConsolation.name);
		        	}
		        	
		        	if (this.seniorsASide != null) {
		        		data.append("seniorsASide", this.seniorsASide, this.seniorsASide.name);
		        	}
		        	
		        	if (this.seniorsConsolation != null) {
		        		data.append("seniorsConsolation", this.seniorsConsolation, this.seniorsConsolation.name);
		        	}

		        	
		        	axios.post('/admin/mens/results', data)
		        	.then ((response) => {
		        		this.$refs.mensResults.disable = true;
		        		
		        		setTimeout(function(){window.location.href = '/admin/mens/results/';},0);
		        		
		        	})
		        	.catch((error) => {

		        		console.log(error);
		        		alert('There was an error: ' + error.message);
		        	});
		        }
				
			}
	    }
    }
</script>