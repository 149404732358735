<template>
  <v-card>
    <v-container fluid>
        <div class="overline mb-4">Golf Club</div>
      <v-row
        align="center"
      >
        <v-col cols="12">
          <v-autocomplete
            v-model="clubname"
            :items="dataclubs"
            dense
            filled
            label="Select Your Golf Club"
            noDataText="Please select a club from the list"
            :rules="validation"
            name="golfclub"
          ></v-autocomplete>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>
    <script>
        export default {
            props: ['clubs'],
            data: function() {
                return {
                  dataclubs: this.clubs,
                  clubname: null,
                  validation: [
                    value => !!value || 'Required.',
                  ]
                }
            },
            
        }
    </script>