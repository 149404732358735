<template>
	<v-form ref="ladiesSignUp" @submit.prevent="ladiesSignUp" method="POST" lazy-validation v-model="valid">
		<v-card>
			<v-container fluid>
				<div class="overline mb-4">Results</div>
				<div class="overline mb-4 nice_red" style="font-size: 10px;">* All fields must be filled out with current &amp; valid information</div>
				<v-row align="center">
					<v-col cols="12">
						<v-select :items="flights" :name="flight" v-model="flight" label="Flight" dense></v-select>
					</v-col>
				</v-row>
				<v-row align="center">
					<v-col cols="12">
						<v-text-field v-model="winningname" :name="winningname" label="Winning Team Name" :rules="nameRules"></v-text-field>
					</v-col>
				</v-row>

			</v-container>
		</v-card>

		<v-divider inset></v-divider>
		<v-btn type="submit" ref="submit" rounded block color="primary" dark>Submit Result</v-btn>
		<div class="form">
			<div class="form">
				<div class="g-recaptcha" data-sitekey="6LdEgEAUAAAAAHeMNcW_rv4iR9IP1j7vL7gMZxH3"></div>
			</div>
		</div>
	</v-form>
</template>
<script>
    export default {
        props: ['clubs'],
        data: function() {
            return {
            	valid: true,
            	winningname: null,
				flights: ['Championship Draw', 'Championship Consolation','First Draw', 'First Consolation','Second Draw', 'Second Consolation'],
				flight: null,
				nameRules: [
					value => !!value || 'Required.',
					value => (value && value.length >= 3) || 'Min 3 characters',
					
				]
            }
        },
        methods: {
    	ladiesSignUp: function() {
				//if ((this.$refs.p1.validate()) && (this.$refs.p2.validate()) && (this.$refs.clubs.validate())) {
		        if (this.$refs.ladiesSignUp.validate()) {
		        	let data = {
		            	winningname: this.winningname,
						flight: this.flight,
		        	};
		        	axios.post('/ladies/resultssubmit', data)
		        	.then ((response) => {
		        		this.$refs.ladiesSignUp.disable = true;
		        		setTimeout(function(){window.location.href = '/ladies/resultssubmit/thanks';},0);
		        		
		        	})
		        	.catch((error) => {

		        		console.log(error);
		        		alert('There was an error: ' + error.message);
		        	});
		        }
				
			}
	    }
    }
</script>