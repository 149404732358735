<template>
	<v-form ref="ladiesResults" @submit.prevent="ladiesResults" method="POST" lazy-validation v-model="valid">
		<v-card>
			<v-toolbar dark>

				<v-toolbar-title>First Draw</v-toolbar-title>

			</v-toolbar>
			<v-container fluid>

				<v-row align="center">
					<v-file-input label="Draw PDF" outlined dense v-model="first" name="first"></v-file-input>
					<v-subheader>{{firstdrawprop}}</v-subheader>
				</v-row>
				<v-row align="center">
					<v-file-input label="Consolation PDF" outlined dense v-model="firstConsolation" name="firstConsolation"></v-file-input>
					<v-subheader>{{firstconsolationprop}}</v-subheader>
				</v-row>
				
			</v-container>
		</v-card>

		<v-card>
			<v-toolbar dark>

				<v-toolbar-title>Second Draw</v-toolbar-title>

			</v-toolbar>
			<v-container fluid>

				<v-row align="center">
					<v-file-input label="Draw PDF" outlined dense v-model="second" name="second"></v-file-input>
					<v-subheader>{{seconddrawprop}}</v-subheader>
				</v-row>
				<v-row align="center">
					<v-file-input label="Consolation PDF" outlined dense v-model="secondConsolation" name="secondConsolation"></v-file-input>
					<v-subheader>{{secondconsolationprop}}</v-subheader>
				</v-row>
				
			</v-container>
		</v-card>

		<v-card>
			<v-toolbar dark>

				<v-toolbar-title>Championship Draw</v-toolbar-title>

			</v-toolbar>
			<v-container fluid>

				<v-row align="center">
					<v-file-input label="Draw PDF" outlined dense v-model="championship" name="championship"></v-file-input>
					<v-subheader>{{championshipdrawprop}}</v-subheader>
				</v-row>
				<v-row align="center">
					<v-file-input label="Consolation PDF" outlined dense v-model="championshipConsolation" name="championshipConsolation"></v-file-input>
					<v-subheader>{{championshipconsolationprop}}</v-subheader>
				</v-row>
				
			</v-container>
		</v-card>





		<v-divider inset></v-divider>

		<v-divider inset></v-divider>
		<v-btn type="submit" ref="submit" rounded block color="primary" dark>Save</v-btn>
		<div class="form">
			<div class="form">
				<div class="g-recaptcha" data-sitekey="6LdEgEAUAAAAAHeMNcW_rv4iR9IP1j7vL7gMZxH3"></div>
			</div>
		</div>
	</v-form>
</template>
<script>
    export default {
        data: function() {
            return {
            	valid: true,
            	first: null,
            	firstConsolation:null,
            	second: null,
            	secondConsolation:null,
            	championship: null,
            	championshipConsolation:null,
            }
        },
        props: ["firstdrawprop", "firstconsolationprop", "seconddrawprop", "secondconsolationprop", "championshipdrawprop", "championshipconsolationprop"],
        methods: {
    	ladiesResults: function() {
				//if ((this.$refs.p1.validate()) && (this.$refs.p2.validate()) && (this.$refs.clubs.validate())) {
		        if (this.$refs.ladiesResults.validate()) {
		        	let data = new FormData();

		        	if (this.first != null) {
		        		data.append("first", this.first, this.first.name);
		        	}
		        	
		        	if (this.firstConsolation != null) {
		        		data.append("firstConsolation", this.firstConsolation, this.firstConsolation.name);
		        	}
		        	
		        	if (this.second != null) {
		        		data.append("second", this.second, this.second.name);
		        	}
		        	
		        	if (this.secondConsolation != null) {
		        		data.append("secondConsolation", this.secondConsolation, this.secondConsolation.name);
		        	}
		        	
		        	if (this.championship != null) {
		        		data.append("championship", this.championship, this.championship.name);
		        	}
		        	
		        	if (this.championshipConsolation != null) {
		        		data.append("championshipConsolation", this.championshipConsolation, this.championshipConsolation.name);
		        	}
		        	
		        	axios.post('/admin/ladies/results', data)
		        	.then ((response) => {
		        		this.$refs.ladiesResults.disable = true;
		        		
		        		setTimeout(function(){window.location.href = '/admin/ladies/results/';},0);
		        		
		        	})
		        	.catch((error) => {

		        		console.log(error);
		        		alert('There was an error: ' + error.message);
		        	});
		        }
				
			}
	    }
    }
</script>