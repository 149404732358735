<template>
	<v-form ref="ladiesSignUp" @submit.prevent="ladiesSignUp" method="POST" lazy-validation v-model="valid">
       <v-alert v-model="button.alert" text="There was an error with your form.  Please ensure you have completed the form correctly." type="error">There was an error with your form.  Please ensure you have completed the form correctly.</v-alert>

          <v-card id="formtop">
		    <v-container fluid>
		      <v-row
		        align="center"
		      >
		        <v-col cols="12">
		           <v-checkbox v-model="regulations" :name="regulations" label="I have read and understood regulations." :rules="regulationRules">

                   </v-checkbox>
                   <p>You can read the tournament regulations found <a target="_BLANK" href="https://bestball.rileys.com/ladies/regulations">HERE</a></p>
		        </v-col>
		      </v-row>
		    </v-container>
		  </v-card>

          <v-divider inset></v-divider>

		<v-card>
			<v-container fluid>
				<div class="overline mb-4">Player One <small>(Please have the lower handicap index player be Player 1)</small></div>
				<div class="overline mb-4 nice_red" style="font-size: 10px;">* All fields must be filled out with current &amp; valid information</div>
				<v-row align="center">
					<v-col cols="4">
						<v-text-field v-model="onefname" :name="onefname" label="First Name" :rules="nameRules"></v-text-field>
					</v-col>
					<v-col cols="8">
						<v-text-field v-model="onelname" :name="onelname" label="Last Name" :rules="nameRules"></v-text-field>
					</v-col>
				</v-row>
				<v-row align="center">
					<v-col cols="12">
						<v-text-field v-mask="['(###) ###-####']" masked="true" v-model="onephone" :name="onephone" label="Phone (###) ###-####" :rules="phoneRules"></v-text-field>
					</v-col>
				</v-row>
				<v-row align="center">
					<v-col cols="12">
						<v-text-field v-model="oneemail" :name="oneemail" label="Email" :rules="emailRules"></v-text-field>
					</v-col>
				</v-row>
				<v-row align="center">
					<v-col cols="12">
						<v-text-field v-model="onehandicap" :name="onehandicap" label="Handicap Index" :rules="handicapRules"></v-text-field>
					</v-col>
				</v-row>

			</v-container>
		</v-card>

		<v-divider inset></v-divider>

		<v-card>
			<v-container fluid>
				<div class="overline mb-4">Player Two <small>(Please have the higher handicap index player be Player 2)</small></div>
				<div class="overline mb-4 nice_red" style="font-size: 10px;">* All fields must be filled out with current &amp; valid information</div>
				<v-row align="center">
					<v-col cols="4">
						<v-text-field v-model="twofname" :name="twofname" label="First Name" :rules="nameRules"></v-text-field>
					</v-col>
					<v-col cols="8">
						<v-text-field v-model="twolname" :name="twolname" label="Last Name" :rules="nameRules"></v-text-field>
					</v-col>
				</v-row>
				<v-row align="center">
					<v-col cols="12">
						<v-text-field v-mask="['(###) ###-####']" masked="true" v-model="twophone" :name="twophone" label="Phone (###) ###-####" :rules="phoneRules"></v-text-field>
					</v-col>
				</v-row>
				<v-row align="center">
					<v-col cols="12">
						<v-text-field v-model="twoemail" :name="twoemail" label="Email" :rules="emailRules"></v-text-field>
					</v-col>
				</v-row>
				<v-row align="center">
					<v-col cols="12">
						<v-text-field v-model="twohandicap" :name="twohandicap" label="Handicap Index" :rules="handicapRules"></v-text-field>
					</v-col>
				</v-row>

			</v-container>
		</v-card>
		<v-divider inset></v-divider>
		<v-card>
		    <v-container fluid>
		        <div class="overline mb-4">Golf Club</div>
		      <v-row
		        align="center"
		      >
		        <v-col cols="12">
		          <v-autocomplete
		            v-model="clubname"
		            :items="dataclubs"
		            dense
		            filled
		            label="Select Your Golf Club"
		            noDataText="Please select a club from the list"
		            :rules="clubRules"
		            name="golfclub"
		          ></v-autocomplete>
		        </v-col>
		      </v-row>
		    </v-container>
		  </v-card>
		<v-divider inset></v-divider>
		<v-btn :disabled="button.isLoading" type="submit" ref="submit" rounded block color="primary" dark>{{ button.text }}</v-btn>
		<div class="form">
			<div class="form">
				<div class="g-recaptcha" data-sitekey="6LdEgEAUAAAAAHeMNcW_rv4iR9IP1j7vL7gMZxH3"></div>
			</div>
		</div>
	</v-form>
</template>
<script>
    import {mask} from 'vue-the-mask';
    export default {
        directives:{mask},
        props: ['clubs'],
        data: function() {
            return {
            	valid: true,
            	dataclubs: this.clubs,
            	clubname: null,
            	onefname: null,
				onelname: null,
				onephone: null,
				oneemail: null,
				onehandicap: null,
				twofname: null,
				twolname: null,
				twophone: null,
				twoemail: null,
				twohandicap: null,
                regulations: false,
                button: {
                    isLoading: false,
                    text: "Sign Up",
                    alert: false,
                },
				nameRules: [
					value => !!value || 'Required.',
					value => (value && value.length >= 3) || 'Min 3 characters',

				],
				phoneRules: [
					value => !!value || 'Required.',
					value => (value && value.length >= 10) || 'Min 10 characters',
				],
				emailRules: [
					value => !!value || 'Required.',
					//regex not working....
					//value => /.+@.+\..+/.test(v) || 'E-mail must be valid',  // /^([A-Za-z0-9_\-.+])+@([A-Za-z0-9_\-.])+\.([A-Za-z]{2,})$/
				],
				handicapRules: [
					value => !!value || 'Required.',
				],
				clubRules: [
					value => !!value || 'Required.',
				],
				regulationRules: [
					value => !!value || 'Required.',
				]
            }
        },
        methods: {
    	ladiesSignUp: function() {
				//if ((this.$refs.p1.validate()) && (this.$refs.p2.validate()) && (this.$refs.clubs.validate())) {
                this.button.isLoading = true;
                this.button.text = "Submitting...";
                this.button.alert = false;
		        if (this.$refs.ladiesSignUp.validate()) {
		        	let data = {
		        		clubname: this.clubname,
		            	onefname: this.onefname,
						onelname: this.onelname,
						onephone: this.onephone,
						oneemail: this.oneemail,
						onehandicap: this.onehandicap,
						twofname: this.twofname,
						twolname: this.twolname,
						twophone: this.twophone,
						twoemail: this.twoemail,
						twohandicap: this.twohandicap,
                        regulations: this.regulations,
		        	};
		        	axios.post('/ladies/entry', data)
		        	.then ((response) => {
                        this.button.isLoading = false;
                        this.button.text = "Sign Up";
		        		this.$refs.ladiesSignUp.disable = true;
		        		console.log(response.data.paypal_invoice_id);
		        		setTimeout(function(){window.location.href = '/ladies/entry/' + response.data.entry_id;},0);

		        	})
		        	.catch((error) => {
                        this.button.isLoading = false;
                        this.button.text = "Sign Up";
                        document.getElementById('formtop').scrollIntoView();
		        		console.log(error);
		        		alert('There was an error: ' + error.message);
		        	});
		        } else {
                    this.button.isLoading = false;
                    this.button.alert = true;
                    this.button.text = "Sign Up";
                    document.getElementById('formtop').scrollIntoView();
                }

			}
	    }
    }
</script>
