<template>
	<v-card>
		<v-container fluid>
			<div class="overline mb-4">Player {{ number }}</div>
			<div class="overline mb-4 nice_red" style="font-size: 10px;">* All fields must be filled out with current &amp; valid information</div>
			<v-row align="center">
				<v-col cols="4">
					<v-text-field v-model="fname" :name="number + '-fname'" v-bind:Fname="number + 'FName'" label="First Name" :rules="nameRules"></v-text-field>
				</v-col>
				<v-col cols="8">
					<v-text-field v-model="lname" :name="number + '-lname'" label="Last Name" :rules="nameRules"></v-text-field>
				</v-col>
			</v-row>
			<v-row align="center">
				<v-col cols="12">
					<v-text-field v-model="phone" :name="number + '-phone'" label="Phone" :rules="phoneRules"></v-text-field>
				</v-col>
			</v-row>
			<v-row align="center">
				<v-col cols="12">
					<v-text-field v-model="email" :name="number + '-email'" label="Email" :rules="emailRules"></v-text-field>
				</v-col>
			</v-row>
			<v-row align="center">
				<v-col cols="12">
					<v-text-field v-model="handicap" :name="number + '-handicap'" label="Handicap Factor" :rules="handicapRules"></v-text-field>
				</v-col>
			</v-row>

		</v-container>
	</v-card>
</template>
<script>
export default {
	props: [
		'FName',
		'LName',
		'Phone',
		'Email',
		'Handicap'
	],
	data: () => ({
		fname: null,
		lname: null,
		phone: null,
		email: null,
		handicap: null,
		nameRules: [
			value => !!value || 'Required.',
			value => (value && value.length >= 3) || 'Min 3 characters',
			
		],
		phoneRules: [
			value => !!value || 'Required.',
			value => (value && value.length >= 10) || 'Min 10 characters',
		],
		emailRules: [
			value => !!value || 'Required.',
			//regex not working....
			//value => /.+@.+\..+/.test(v) || 'E-mail must be valid',  // /^([A-Za-z0-9_\-.+])+@([A-Za-z0-9_\-.])+\.([A-Za-z]{2,})$/
		],
		handicapRules: [
			value => !!value || 'Required.',
		]
	}),
	props: ['number'],
}
</script>